import Vue from 'vue';
import Vuex from 'vuex';
import { getSession, setSession, getLocalStorage, setLocalStorage } from '../utils/funs/storage';
import config from '../config';
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
        lang: getLocalStorage(config._LANG_KEY) || config._DEFAULT_LANG,
		token: getSession(config._TOKEN_KEY),
		showLoading: false,
        userinfo: getLocalStorage("userinfo") || {},
		cart: '',
		showLogo: true,
		showNav: true,
		showSetFundpassBox: false,
		showWithdrawlBox: false,
		showTtransferBox: false,
		showDepositSwapBox: false,
		showInviteBox: false,
		showLangBox: false,
		showMask: false,
		showBtmBox: false,
		convertReverse: false,
		approvedUsdt: 1000,
		approvedStd: 20000,
	},
	mutations: {
		setShowLogo(state, show){
			state.showLogo = show;
		},
		setCart(state, cart){
			state.cart = cart;
		},
		setConvertReverse(state, reverse){
			state.convertReverse = reverse;
		},
		setApprovedUsdt(state, amount){
			state.approvedUsdt = amount;
		},
		setApprovedStd(state, amount){
			state.approvedStd = amount;
		},
		setShowNav(state, show){
			state.showNav = show;
		},
		setMask(state, show){
			state.showMask = show;
		},
		setBtmBox(state, show){
			state.showBtmBox = show;
			if(!show){
				state.showMask = false;
				state.showSetFundpassBox=false;
				state.showWithdrawlBox=false;
				state.showDepositSwapBox=false;
				state.showInviteBox=false;
				state.showLangBox=false;
				state.showTtransferBox=false;
			}else{
				state.showMask = true;
			}
		},
		setShowTtransferBox(state, show){
			state.showTtransferBox = show;
		},
		setShowSetFundpassBox(state, show){
			state.showSetFundpassBox = show;
		},
		setShowWithdrawlBox(state, show){
			state.showWithdrawlBox = show;
		},
		setShowDepositSwapBox(state, show){
			state.showDepositSwapBox = show;
		},
		setShowInviteBox(state, show){
			state.showInviteBox = show;
		},
		setShowLangBox(state, show){
			state.showLangBox = show;
		},
		setLang(state, lang) {
			state.lang = lang || config._DEFAULT_LANG;
			setLocalStorage(config._LANG_KEY, lang);
		},
		setToken(state, token) {
			state.token = token;
			setSession(config._TOKEN_KEY, token);
		},
		setLoading(state, loading){
			state.showLoading = loading
		},
		setUserinfo(state, userinfo) {
			state.userinfo = userinfo;
			setLocalStorage("userinfo", userinfo);
		},
	},
	actions: {

	},
	modules: {

	}
});
