<template>
  <div :class="['navbar', { fixed: fixed }]">
    <a-icon v-if="showBackButton" @click="goBack" class="back-button" type="left" />
    <h1 class="title">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    title: {
      type: String,
      required: true
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goBack() {
      this.$emit('go-back');
    }
  }
};
</script>

<style scoped>
.navbar {
  display: flex;
  align-items: center;
  padding: 28px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #ddd;
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.back-button {
  position: absolute;
  left: 28px;
}

.title {
  flex: 1;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
}
</style>