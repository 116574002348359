<template>
  <div class="contact-card">
    <div class="flex container-box" v-if="type === 'add'" @click="handleAdd">
      <a-icon class="title-icon" type="plus-square" />
      <span class="title">添加联系人</span>
      <a-icon type="right" />
    </div>
    <div class="flex container-box" v-else-if="type === 'edit'" @click="handleEdit">
      <a-icon class="title-icon" type="user" />
      <div class="title contact-phone">
        <span>姓名: {{ name }}</span>
        <span>电话: {{ contact }}</span>
      </div>
      <a-icon type="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactCard",
  props: {
  type: {
    type: String,
    required: true
  },
  name: {
    type: String,
    default: ''
  },
  contact: {
    type: String,
    default: ''
  }
},
data() {
  return {
    
  };
},
  
  methods: {
    handleAdd() {
      this.$emit('add');
    },
    handleEdit() {
      this.$emit('edit');
    }
  }
};
</script>

<style lang="less" scoped>
.contact-card {
  padding: 32px 24px;
  margin: 0 auto;
  background: #ffffff;
  position: relative;
  &::before {
    background: repeating-linear-gradient(-45deg, #ff976a 0, #ff976a 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
    content: "";
    background-size: 180px;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .container-box {
    position: relative;
    padding: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-icon {
      position: absolute;
      left: 16px;
      font-size: 72px;
      color: #a7a7a7;
    }
    .title {
      padding-left: 100px;
      font-size: 36px;
    }.contact-phone {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 28px;
    }
  }
}


</style>
