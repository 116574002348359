<template>
    <div class="goods">
        <div class="back-btn">
            <a-icon @click="$router.go(-1)" type="left" />
        </div>
        <Carousel class="banners" :radius="false" :imgList="fmtBanners(goodsInfo.images)"></Carousel>
        <div class="radius-box goods-info">
            <h1 class="title">{{goodsInfo.title}}</h1>
            <div class="price">
                <span class="color-red sell-price"><i class="symbol">$</i>{{goodsInfo.price}}</span>
                <span class="market-price">{{ goodsInfo.market_price }}</span>
            </div>
            <div class="tags-sell-count">
                <div class="tags">
                    <a-tag v-for="tag in tagArr(goodsInfo.tags)" :key="tag.name" :color="tag.color">{{tag.name}}</a-tag>
                </div>
                <div class="sell-count">
                    销量: {{goodsInfo.sale_count}}
                </div>
            </div>
            <div class="stars">
                <div>宝贝评价</div>
                <a-rate class="color-red" :value="5" disabled />
            </div>
        </div>
        <div class="radius-box goods-content" v-if="goodsInfo.content">
            <div class="label">商品详情</div>
            <div class="content" v-html="goodsInfo.content.content"></div>
        </div>

        <div class="buy-bar">
            <div class="color-gray flex  navicons">
                <div class="nav-icon" @click="$router.push({name:'home'})">
                    <a-icon type="home" />
                    <span class="text">首页</span>
                </div>
                <div class="nav-icon" @click="collectGoods">
                    <a-icon class="color-red" v-if="collected" type="heart" theme="filled" />
                    <a-icon v-else type="heart" />
                    <span class="text" :class="collected?'color-red':''">收藏</span>
                </div>
                <div class="nav-icon">
                    <a-icon type="share-alt" />
                    <span class="text">分享</span>
                </div>
            </div>
            <div class="flex right">
                <Stepper v-model="quantity" :step="1" :min="1" :max="10" />
                <a-button class="buy-btn" type="danger" @click="handleBuy">购买</a-button>
            </div>

        </div>
    </div>
</template>
<script>
import Carousel from '@/components/Carousel.vue';
import Stepper from '@/components/Stepper.vue';
export default {
    name: 'Goods',
    data(){
        return {
            collected: false,
            quantity: 1,
            goodsInfo: {},
            tagColors: ['orange','pink','green','blue','purple','red']
        }
    },
    components:{
        Carousel, Stepper
    },
    mounted(){
        this.getGoods();
        this.getIsCollect();
    },
    methods:{
        getGoods(){
            let _this = this
            this.$http.call(this.$http.api.mallGoods,{
                data: {
                    id: this.$route.params.id
                }
            }).then(function(res){
                console.log(res.data)
                if(res.data.code == '200'){
                    _this.goodsInfo = res.data.data;
                }
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
        getIsCollect(){
            let _this = this
            this.$http.call(this.$http.api.mallIsCollect,{
                data: {
                    goods_id: this.$route.params.id
                }
            }).then(function(res){
                console.log(res.data)
                if(res.data.code == '200'){
                    _this.collected = res.data.data;
                }
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
        collectGoods(){
            let _this = this
            this.$http.call(this.$http.api.mallCollect,{
                data: {
                    goods_id: this.$route.params.id,
                    collect_status: this.collected?0:1
                }
            }).then(function(res){
                console.log(res.data)
                if(res.data.code == '200'){
                    _this.collected = res.data.data;
                }
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
        randomColor(){
            return this.tagColors[Math.floor(Math.random() * this.tagColors.length)];
        },
        fmtBanners(banners) {
            if(!banners) return [];
            let _imgs = JSON.parse(banners);
            return _imgs.map(item => {
                return {
                    url: item
                }
            })
        },
        tagArr(tags){
            if(!tags) return [];
            return tags.split(',').map(item => {
                return {
                    name: item,
                    color: this.randomColor()
                }
            })
        },
        handleBuy() {
            let _cart = {
                goods_id: this.goodsInfo.id,
                goods_image: this.goodsInfo.thumb,
                goods_title: this.goodsInfo.title,
                goods_price: this.goodsInfo.price,
                quantity: this.quantity
            };
            this.$store.commit('setCart', _cart);
            this.$router.push({name: 'checkout'});
        }
    }
}
</script>
<style lang="less" scoped>
.goods{
    height: 100vh;
    background: #fff;
    .back-btn {
        position: fixed;
        left: 28px;
        top: 28px;
        background: #f1f1f181;
        color: #818181;
        font-size: 50px;
        width: 100px; height: 100px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
    }
    .banners {
        width: 100%;
        height: 100vw;
        margin-bottom: 28px;
        z-index: -1;
    }
    .radius-box {
        position: relative;
        background: linear-gradient(to bottom, #f1f1f1, #fff);
        border-top-left-radius: 64px;
        border-top-right-radius: 64px;
        padding: 64px 36px 200px;
        margin-top: -100px;
        z-index: 9;
    }
    .goods-info {
        padding-bottom: 150px;
        .title {
            font-size: 40px;
            text-align: left;
            font-weight: bold;
            margin-bottom: 24px;
        }
        .price {
            font-size: 50px;
            margin-bottom: 24px;
            text-align: left;
            .sell-price {
                font-size: 50px;
                font-weight: bold;
                i.symbol {
                    font-size: 36px;
                    padding-right: 12px;
                }
            }
            .market-price {
                font-size: 28px;
                color: #999;
                text-decoration: line-through;
                margin-left: 24px;
            }
        }
        .tags-sell-count {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .stars {
            background: #fff;
            border-radius: 28px;
            padding: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 36px;
            box-shadow: 7px 7px 12px rgba(0,0,0,0.1);
        }
    }
    .goods-content {
        .label {
            font-size: 40px;
            font-weight: bold;
            text-align: left;
            margin-bottom: 36px;
            color: #646464;
        }
        .content {
            img {
                max-width: 100% !important;
            }
        }
    }
    .buy-bar {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 -7px 24px rgba(128,0,0,0.1);
        padding: 24px 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 99;
        font-size: 36px;
        .navicons {
            font-size: 50px;
            color: #a7a7a7;
            .nav-icon {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 24px;
                .text {
                    font-size: 24px;
                }
            }
        }
        .buy-btn {
            margin-left: 50px;
        }
    }
}
</style>
<style>
.goods-content>.content>p>img{
    max-width: 100% !important;
}
</style>