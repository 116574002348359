module.exports = {
    'common': {
        'currency': 'BCT',
        'submit': 'Submit',
        'register_btn': 'Register',
        'login_btn': 'Login',
        'more': 'MORE',
        'back': 'BACK',
        'ok': 'OK',
        'lackOfBalance': 'Lack of Balance',
        'submitSuccess': 'submit success',
        'copySuccess':'copy success',
        'saveImgTips':'long tap or screenshot',
        'commingsoon': 'Coming soon...',
        'noMoreData': 'NO MORE DATA',
    }, 'navs':{
        'home': 'Home',
        'invite': 'Team',
        'wallet': 'Wallet',
        'user': 'User'
    }, 'register': {
        'label': {
            'referer': 'Referee',
            'account': 'Your Wallet',
            'password': 'Password',
            'cpassword': 'Confirm',
            'oxaddress': 'Wallet Address',
        },
        'tips': {
            'iptError': 'Invalid input',
            'diff_password': 'Password not consistent',
            'success': 'successful,redirecting to login...'
        },
        'already_registered': 'Already have an account?'
    }, 'login': {
        'label': {
            'account': 'Wallet Address',
            'password': 'Password',
        },
        'forget': 'Forget password?',
        'tips': {
            'iptError': 'Invalid input',
            'forget': 'Contact customer service to retrieve it',
        }
    }, 'tinjiku': {
        'title': 'AI Tenjiku',
        'join': 'Donate',
    }, 'wallet':{
        'amountLabel': 'Assets Balance',
        'lockedLabel': 'locking',
        'totalFlow': 'Total Flow',
        'released': 'Available',
        'gfd': 'GFD',
        'defiDeposit': 'DeFi Deposit',
        'defiProfits': 'DeFi Profits',
        'planId': 'Plan No.',
        'gsil': {
            'title':'Co-create',
            'lock': 'Locked',
            'equity': 'Equity'
        },
        'defiLock': 'Node Plan',
        'endtime': 'END TIME',
        'noFundpassText': 'You have not set a fund password',
        'goSet': 'Go Set',
        'transfer': 'Transfer',
        'transfers':{
            'receiver': 'Receive Wallet Address',
            'amount': 'Amount'
        },
        'withdrawl':{
            'deposit': 'Defi',
            'btn': 'Withdraw',
            'buy': 'Buy',
            'account': 'Wallet Address',
            'amount': 'Quantity',
            'depositAmount': 'Amount',
            'password': 'Fund Password',
            'canuse': 'Balance',
            'multipleTips': 'The amount must be a multiple of {amount}',
            'lgMin': 'The amount must be greater than {amount}',
            'lsMax': 'The amount must be less than {amount}',
            'notWithdrawTime': 'Current non-withdrawal period'
        },
        'listLabel':{
            'records': 'Records',
            'profit': 'Community',
            'release': 'Release',
            'withdrawl': 'Withdraw',
            'depositProfits': 'Profits',
        },
        'depositProfitType':['Node Profit','Same Level Profit'],
        'depositNodes': ['DaoVIP','Partner','The Origin','Fulcrum','Node'],
        'typeText': [
            'register gifts','sign in', 'share', 'buy land', 'straight edge away', 'in practice', 'water consumption','banyan tree picking', 'withdraw', 'cash back', 'with repairing award', 'land management award', 'system control', 'meditation award', 'pledge', 'pledge income', 'pledge expire', 'transfer', 'received transfer', 'hedge buying land', 'hedge in practice','hedge upgrade land', 'hedge upgrade practice', 'buy goods','cancel order'
        ]
    }, 'deposit':{
        'btnEnd': 'close',
        'btnRenew': 'renew',
        'closed': 'closed',
        'earning': 'earning',
    }, 'security':{
        'title': 'Security',
        'fundPass': 'Fund Password',
        'loginPass': 'Login Password',
        'oldPassword': 'Old Password',
        'password': 'New Password',
        'cpassword': 'Confirm',
        'cantbnull': 'Please enter both the original password and the new password',
        'diffrentPassword':'Password not consistent',
        'needFundpass': 'Please enter the fund password'
    }, 'profile': {
        'titles':{
            'invite': 'Invite',
            'performance': 'Performances',
            'accountSecurity': 'Security',
            'language': 'Language',
            'aboutUs': 'About us',
            'logout': 'Logout',
        },
        'invite':{
            'saveUrl': 'Copy',
            'saveImg': 'Save',
        },
        'cardLevelText': [
            "Global Card","Silver Card","Gold Card","Platinum Card","Jade Card","Diamond Card","Black Card"
        ],
    }, 'signin':{
        'title': 'SIGNIN 7 Days',
    }, 'budhi':{
        'groundTitle': 'Buy Land',
        'groundUnit': 'Acreage',
        'spTitle': 'Spirit practice',
        'sps': ['善念','善心','善緣','樂奉','善捐'],
    }, 'withdrawl':{
        'title': 'Withdraw',
        'lackOfBalance': 'Insufficient balance',
        'cantbnull': 'Please enter the withdrawal amount and fund password',
        'forget':'Forget?',
        'forgetPassTips':'Reset fund password in user page',
        'placeholder': 'Amount you want to withdraw',
        'amount':'Amount',
        'status':["Pending","passed","Rejected"],
        'statusColor':['','green','red'],
    }, 'team':{
        'time': 'Time',
        'searchPlaceholder': 'Search Addresses',
        'regTime': 'Register Time',
        'teamNum': 'Teams',
        'tjNum': 'Recommends',
        'BCHs': 'BCT',
        'performance': 'Performance',
        'locked_bt': 'Locked BCT',
        'bt': 'BCT',
        'gd': 'Merit',
        'ground': 'LAND',
        'gfd': 'GFD',
        'stack': 'Stack',
    }, 'share':{
        'title': 'Invite friends',
        'text': 'Join us',
    }, 'home':{
        'navTitles': {
            'tinjiku': 'Tinjiku',
            'defi': 'DeFi',
            'gongxiu': 'Dharma',
            'faxin': 'Shield',
            'signin': '每日签到',
            'bct': 'CoCreate',
            'game': 'GameFi',
            'nfts': 'Nfts',
            'exchange': 'circulation',
            'global': 'Supermarket',
            'budhi': '菩提園',
            'stake': '合約質押',
        },
    }, 'gongxiu': {
        'rxys': '日行一善',
        'js': '建寺',
        'gf': '供佛',
        'gz': '供齋',
        'fh': '法會',
        'zyin': '助印',
        'gdeng': '供燈',
        'gdx': '功德箱',
        'svx': '隨喜'
    }, 'defi': {
        'title': 'Co-create BCT',
        'countLabel': 'Counter',
        'join': 'Join now',
        'min': 'Minimum 100USDT',
        'lackofbalance': 'Insufficient balance, the balance must be greater than the transaction amount',
    }, 'stake': {
        'days': 'days'
    },'langs':[
        {'title': 'English','code': 'en'},
        {'title': '繁體中文','code': 'hk'},
    ]
}