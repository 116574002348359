module.exports = {
    'common': {
        'currency': 'BCT',
        'submit': '提交',
        'register_btn': '註冊',
        'login_btn': '登陸',
        'more': '更多',
        'back': '返回',
        'ok': '確定',
        'lackOfBalance': '餘額不足',
        'submitSuccess': '提交成功',
        'copySuccess':'複製成功',
        'saveImgTips':'長按或截屏保存',
        'commingsoon': '敬请期待',
        'noMoreData': '沒有更多數據',
    }, 'navs':{
        'home': '首頁',
        'invite': '团队',
        'wallet': '錢包',
        'user': '我的'
    }, 'register': {
        'label': {
            'referer': '推薦人',
            'account': '錢包地址',
            'password': '密碼',
            'cpassword': '確認密碼',
            'oxaddress': '以太系钱包地址'
        },
        'tips': {
            'iptError': '請輸入錢包地址和密碼',
            'diff_password': '密碼不一致',
            'success': '註冊成功,正在自動登錄...'
        },
        'already_registered': '已有帳戶?'
    }, 'login': {
        'label': {
            'account': '錢包地址',
            'password': '密碼',
        },
        'forget': '忘記密碼?',
        'tips': {
            'iptError': '非法輸入',
            'forget': '聯繫客服人員重置',
        }
    }, 'tinjiku': {
        'title': 'AI天竺國',
        'join': '捐赠建寺',
    }, 'wallet':{
        'amountLabel': '總資產',
        'lockedLabel': '鎖定權益',
        'totalFlow': '流水總權益',
        'released': '可用餘額',
        'gfd': '善果',
        'defiDeposit': 'DeFi增值计划',
        'defiProfits': 'DeFi派息',
        'planId': '计划ID',
        'gsil': {
            'title':'開山计划',
            'lock': '质押',
            'equity': '权益'
        },
        'defiLock': '節點計畫',
        'endtime': '到期時間',
        'noFundpassText': '您還沒有設置資金密碼',
        'goSet': '去設置',
        'transfer': '轉帳',
        'transfers':{
            'receiver': '接收地址',
            'amount': '數量'
        },
        'withdrawl':{
            'deposit': 'Defi',
            'btn': '提現',
            'buy': '购入',
            'account': '錢包地址',
            'amount': '提現數量',
            'depositAmount': '轉存金額',
            'password': '資金密碼',
            'canuse': '餘額',
            'multipleTips': '金額必須為{amount}的倍數',
            'lgMin': '金額必須大於{amount}',
            'lsMax': '金額必須小於{amount}',
            'notWithdrawTime': '當前非提現時間段'
        },
        'listLabel':{
            'records': '資金紀錄',
            'profit': '社區分紅',
            'release': '锁倉釋放',
            'withdrawl': '提現',
            'depositProfits': '已派息',
        },
        'depositProfitType':['極差獎','平級獎'],
        'depositNodes': ['DAO会员','合夥人','同台原點','同台支點','同台節點'],
        'typeText': [
            '注册赠送','签到赠送','分享赠送','購買土地贈送','直緣贈送','进场修行','浇水消耗','菩提樹採摘','提現','提現退回','同修獎','土地管理獎','系统调节', '共修奖','質押','質押收益','質押到期','轉帳','收到轉帳','對衝購買土地','對衝進場修行','對衝升級土地','對衝升級修行','购买商品','取消订单'
        ]
    }, 'deposit':{
        'btnEnd': '結束收益',
        'btnRenew': '續存增值',
        'closed': '已結束',
        'earning': '盈利中',
    }, 'security':{
        'title': '帳戶安全',
        'fundPass': '資金密碼',
        'loginPass': '登錄密碼',
        'oldPassword': '原始密碼',
        'password': '新密碼',
        'cpassword': '確認密碼',
        'cantbnull': '請輸入原始密碼和新密碼',
        'diffrentPassword':'密碼不一致',
        'needFundpass': '請輸入資金密碼'
    }, 'profile': {
        'titles':{
            'invite': '邀請好友',
            'performance': '共修業績',
            'accountSecurity': '帳戶安全',
            'orders': '我的訂單',
            'collections': '我的收藏',
            'address': '地址管理',
            'language': '選擇語言',
            'aboutUs': '關於我們',
            'logout': '退出系統'
        },
        'invite':{
            'saveUrl': '複製',
            'saveImg': '保存',
        },
        'nodesText': [
            "","居士","普修","值事","主事","监事"
        ],
    }, 'signin':{
        'title': '簽到七天樂',
    }, 'budhi':{
        'groundTitle': '購買土地',
        'groundUnit': '畝',
        'spTitle': '進場修行',
        'sps': ['善念','善心','善緣','樂奉','善捐'],
    }, 'withdrawl':{
        'title': '提現',
        'lackOfBalance': '餘額不足',
        'cantbnull': '請輸入正確的金額和資金密碼',
        'forget':'忘記密碼?',
        'forgetPassTips':'請在個人中心頁面重置資金密碼',
        'placeholder': '提現金額',
        'amount':'提現數量',
        'status':["審核中","通過","拒絕"],
        'statusColor':['','green','red'],
    }, 'team':{
        'time': '時間',
        'searchPlaceholder': '檢索錢包地址',
        'regTime': '註冊時間',
        'teamNum': '善信人數',
        'tjNum': '推薦數',
        'BCHs': 'BCT',
        'performance': '總業績',
        'locked_bt': '鎖倉BCT',
        'bt': '可用BCT',
        'gd': '功德',
        'ground': '土地',
        'gfd': '善果',
        'stack': '質押',
    }, 'share':{
        'title': '邀請朋友',
        'text': '加入我們',
    }, 'home':{
        'navTitles': {
            'tinjiku': 'AI天竺國',
            'defi': 'DeFi',
            'gongxiu': '共修護法',
            'faxin': '發心護持',
            'signin': '每日签到',
            'bct': '開山計畫',
            'game': 'GameFi',
            'nfts': 'Nfts',
            'exchange': '善果流通',
            'global': '慈善超市',
            'budhi': '菩提園',
            'stake': '合約質押'
        },
    }, 'gongxiu': {
        'rxys': '日行一善',
        'js': '建寺',
        'gf': '供佛',
        'gz': '供齋',
        'fh': '法會',
        'zyin': '助印',
        'gdeng': '供燈',
        'gdx': '功德箱',
        'svx': '隨喜'
    }, 'defi': {
        'title': '開山計畫',
        'countLabel': '共修人數',
        'join': '加入',
        'min': '最低100USDT',
        'lackofbalance': '余额不足,余额必须大于交易金额',
    }, 'stake': {
        'days': '天'
    }, 'shop': {
        'title': '商城',
        'address': {
            'title': '地址管理'
        },
        'checkout': {
            'title': '結算'
        }
    }, 'langs':[
        {'title': 'English','code': 'en'},
        {'title': '繁體中文','code': 'hk'}
    ]
}