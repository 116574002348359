<template>
<div class="collections">
    <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">{{ $t('profile.titles.collections') }}</span>
    </div>

    <div class="goods-list">
        <a-list :grid="{ gutter: 16, column: 2 }" :data-source="goodsList">
            <a-list-item slot="renderItem" slot-scope="item, index" :key="index">
                <div class="goods-item" @click="$router.push({name: 'goods', params: {id: item.goods_id}})">
                    <img class="goods-image" :src="item.goods.thumb" alt="">
                    <div class="goods-name">{{fmtName(item.goods.title)}}</div>
                    <div class="goods-price">
                        <span class="sell-price">{{ item.goods.price }}</span>
                        <span class="market-price">{{ item.goods.market_price }}</span>
                    </div>
                </div>
            </a-list-item>
        </a-list>
    </div>

</div>
  
</template>
<script>

export default {
  name: "Collections",
  data() {
    return {
      loading: false,
      goodsList: []
    };
  },
  components:{
  },
  mounted() {
      this.getData();
  },
  methods: {
    getData(){
        if(this.loading) return;
        this.loading = true;
        this.$http.call(this.$http.api.mallCollections).then(res=>{
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                if(resp.data){
                    this.goodsList = resp.data;
                }
            }else{
                this.$message.error(resp.data);
            } 
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        });
    },
    fmtName(title){
        return title.length > 15 ? title.slice(0, 15) + '...' : title;
    }
  },
};
</script>
<style lang="less" scoped>
.collections{
    padding: 150px 50px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #fafafa;
    .header{
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        background: #fff;
        z-index: 1;
        color: #333;
        font-size: 16PX;
        display:flex;
        align-items: center;
        height: 125px; line-height: 125px;
        .back{
            position: absolute;
            left: 50px;
            color:#333;
        }
        .title{
            flex: 1;
        }
    }
    .goods-list {
        margin: 24px 36px;
        .goods-item {
            border-radius: 24px;
            padding: 24px;
            background: #fff;
            box-shadow: 0 0 24px rgba(0,0,0,0.1);
            .goods-image {
                width: 100%;
            }
            .goods-name {
                height: 64px; line-height: 64px;
                overflow: hidden;
            }
            .goods-price {
                .sell-price {
                    color: #721212;
                    padding: 12px;
                }
                .market-price {
                    padding: 12px;
                    color: #999;
                    text-decoration: line-through;
                }
            }
        }
    }
}
</style>
