<script>
import Carousel from '@/components/Carousel.vue';
import Navbar from '@/components/Navbar.vue';
import infiniteScroll from 'vue-infinite-scroll';
export default {
    directives: { infiniteScroll },
    name: 'Shop',
    data(){
        return {
            banners: [],
            goodsList: [],
            loading: false,
            busy: false,
            loadedAll: false,
            page: 1,
            pageSize: 20
        }
    },
    components:{
        Carousel,
        Navbar
    },
    mounted(){
        this.getBanners();
    },
    methods:{
        getBanners(){
            let _this = this
            this.$http.call(this.$http.api.mallBanners).then(function(res){
                if(res.data.code == '200'){
                    _this.banners = res.data.data;
                }
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
        getGoods(){
            if(this.loading || this.busy){
                return;
            }
            this.loading = true;
            let _this = this
            this.$http.call(this.$http.api.mallGoodsList, {
                data: {
                    page: this.page,
                    size: this.pageSize
                }
            }).then(function(res){
                console.log(res.data)
                if(res.data.code == '200'){
                    _this.goodsList = _this.goodsList.concat(res.data.data);
                }
                _this.loading = false;
                if(res.data.data.length < _this.pageSize){
                    _this.busy = true;
                    _this.loadedAll = true;
                }else{
                    _this.page++;
                }
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
        handleGoBack() {
            this.$router.push('/home');
        },
        fmtName(title){
            return title.length > 15 ? title.slice(0, 15) + '...' : title;
        }
    }
}
</script>

<template>
  <div class="shop-view">
    <Navbar :title="$t('shop.title')" :fixed="true" :showBackButton="true" @go-back="handleGoBack" />
    <Carousel class="banners" :imgList="banners"></Carousel>
    <div
        v-infinite-scroll="getGoods"
        class="goods-list"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="10"
    >
        <a-list :grid="{ gutter: 16, column: 2 }" :data-source="goodsList">
            <a-list-item slot="renderItem" slot-scope="item, index" :key="index">
                <div class="goods-item" @click="$router.push({name: 'goods', params: {id: item.id}})">
                    <img class="goods-image" :src="item.thumb" alt="">
                    <div class="goods-name">{{fmtName(item.title)}}</div>
                    <div class="goods-price">
                        <span class="sell-price">{{ item.price }}</span>
                        <span class="market-price">{{ item.market_price }}</span>
                    </div>
                </div>
            </a-list-item>
            <div v-if="loading && !busy">
                <a-spin />
            </div>
            <div v-if="loadedAll" class="no-more-data">
                {{$t('common.noMoreData')}}
            </div>
        </a-list>
    </div>
  </div>
</template>

<style lang="less" scoped>
.shop-view {
    padding-top: 128px;
    background: #fafafa;
    height: 100vh;
    overflow-y: scroll;
    .banners {
        margin: 24px 36px 36px;
    }
    .goods-list {
        margin: 24px 36px;
        .goods-item {
            border-radius: 24px;
            padding: 24px;
            background: #fff;
            box-shadow: 0 0 24px rgba(0,0,0,0.1);
            .goods-image {
                width: 100%;
                max-height: 420px;
            }
            .goods-name {
                height: 64px; line-height: 64px;
                overflow: hidden;
            }
            .goods-price {
                .sell-price {
                    color: #721212;
                    padding: 12px;
                }
                .market-price {
                    padding: 12px;
                    color: #999;
                    text-decoration: line-through;
                }
            }
        }
    }
}
</style>