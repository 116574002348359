<template>
<div class="orders">
    <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">{{ $t('profile.titles.orders') }}</span>
    </div>

    <div class="order-list" v-if="orderList.length>0">
        <div class="order-item" v-for="(order,i) in orderList" :key="i">
            <div class="order-amount">
                <span>{{ order.bct_amount/1e6 }}</span> <i class="symbol">BCT</i>
            </div>
            <div class="order-status">
                <a-tag color="orange" v-if="order.status==0">待支付</a-tag>
                <a-tag color="pink" v-if="order.status==1">待发货</a-tag>
                <a-tag color="blue" v-if="order.status==2" @click="receiveGoods(order.id)">收货</a-tag>
                <a-tag color="green" v-if="order.status==3">已完成</a-tag>
                <a-tag color="gray" v-if="order.status==4">已取消</a-tag>
            </div>
            <div class="order-id">{{ order.order_id }}</div>
            <div class="goods-info">
                <img class="goods-image" :src="order.goods_image" alt="" @click="$router.push({name: 'goods', params: {id: order.goods_id}})">
                <div class="goods-title-price">
                    <div class="goods-title">{{ order.goods_title }}</div>
                    <div class="goods-price">{{ order.goods_price }} x {{ order.quantity }}</div>
                    <div class="goods-cost"><span class="symbol">$</span>{{ order.amount }}</div>
                </div>
            </div>
        </div>
    </div>

</div>
  
</template>
<script>

export default {
  name: "Orders",
  data() {
    return {
      loading: false,
      orderList: []
    };
  },
  components:{
  },
  mounted() {
      this.getData();
  },
  methods: {
    getData(){
        if(this.loading) return;
        this.loading = true;
        this.$http.call(this.$http.api.mallOrders).then(res=>{
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                if(resp.data){
                    this.orderList = resp.data;
                }
            }else{
                this.$message.error(resp.data);
            } 
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        });
    },
    receiveGoods(order_id){
        if(this.loading) return;
        this.loading = true;
        this.$http.call(this.$http.api.receiveGoods,{
            data: {order_id}
        }).then(res=>{
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                this.getData();
            }else{
                this.$message.error(resp.data);
            } 
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.orders{
    padding: 150px 32px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #fafafa;
    .header{
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        background: #fff;
        z-index: 1;
        color: #333;
        font-size: 16PX;
        display:flex;
        align-items: center;
        height: 125px; line-height: 125px;
        .back{
            position: absolute;
            left: 50px;
            color:#333;
        }
        .title{
            flex: 1;
        }
    }
    .order-list {
        .order-item {
            border-radius: 24px;
            margin: 36px 0;
            padding: 24px;
            background: #fff;
            box-shadow: 0 0 24px rgba(0,0,0,0.1);
            text-align: left;
            position: relative;
            .order-amount {
                position: absolute;
                bottom: 24px; right: 24px;
                font-size: 40px;
                font-weight: bold;
                .symbol {
                    font-size: 28px;
                }
                color: @primary-color;
            }
            .order-status {
                position: absolute;
                top: 24px; right: 0px;
            }
            .goods-info {
                display: flex;
                align-items: center;
                padding: 24px 0;
            }
            .goods-image {
                width: 128px; height: 128px;
                margin-right: 24px;
            }
            .goods-name {
                height: 64px; line-height: 64px;
                overflow: hidden;
            }
            .goods-price {
                font-size: 28px;
            }
            .goods-cost {
                font-size: 40px;
                .symbol {
                    font-size: 28px;
                }
                color: @primary-color;
            }
        }
    }
}
</style>
