<template>
    <div class="checkout">
        <div class="header">
            <a-icon class="back" @click="$router.go(-1)" type="left" />
            <span class="title">{{ $t('shop.checkout.title') }}</span>
        </div>

        <ContactCard :type="addressInfo?'edit':'add'" @add="$router.push({name:'address'})" @edit="$router.push({name:'address'})" :name="addressInfo?addressInfo.username:''" :contact="addressInfo?addressInfo.phone:''" />

        <div class="goods-list">
            <div class="title">商品详情</div>
            <div class="goods-card" v-if="cart">
                <img class="goods-image" :src="cart.goods_image" alt="">
                <div class="goods-info">
                    <div class="goods-title">
                        {{cart.goods_title}}
                    </div>
                    <div class="price-count">
                        <span>${{cart.goods_price}}</span>
                        <span> x {{ cart.quantity }}</span>
                    </div>
                </div>
                <div class="goods-cost">${{ totalAmount }}</div>
            </div>
        </div>
        <div class="payment-type">
            <div class="title">支付方式</div>
            <div class="payment-card">
                <img class="payment-icon" src="@/assets/imgs/bctlogo.png" alt="">
                <div class="payment-title">
                    <div>佛币支付</div>
                    <div class="price">BCT/USDT ({{ price }})</div>
                </div>
                <a-icon type="check-circle" class="payment-check" theme="filled" />
            </div>
        </div>
        <div class="security-password">
            <div class="title-label">
                <span>资金密码</span>
                <span class="forget-pass" @click="$router.push({name:'security'})">忘记密码?</span>
            </div>
            <a-input v-model="cpassword" type="password" autocomplete="false" placeholder="请输入您的支付密码" />
        </div>
        <div class="paybar">
            <div class="total-cost">
                <span class="label">订单金额: </span> 
                <span class="cost">{{ (totalAmount/price).toFixed(6) }}<i class="symbol">BCT</i></span>
            </div>
            <a-button type="danger" @click="handleBuy">去支付</a-button>
        </div>
    </div>
</template>
<script>
import ContactCard from '@/components/ContactCard.vue';
export default {
    name: 'Checkout',
    data(){
        return {
            loading: false,
            price: 0,
            addressInfo: null,
            cart: '',
            cpassword: ''
        }
    },
    components:{
        ContactCard
    },
    computed: {
        totalAmount(){
            return parseFloat(this.cart?this.cart.goods_price*this.cart.quantity:0).toFixed(2);
        }
    },
    mounted(){
        this.cart = this.$store.state.cart;
        this.getPrice();
        this.getContact();
    },
    methods:{
        getContact(){
            if(this.loading) return;
            this.loading = true;
            this.$http.call(this.$http.api.getUserAddress).then(res=>{
                this.loading = false;
                let resp = res.data;
                if(resp.code=='200'){
                    if(resp.data){
                        this.addressInfo = resp.data;
                    }
                }else{
                    this.$message.error(resp.data);
                } 
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
        getPrice(){
            this.$http.call(this.$http.api.price).then(res=>{
                let resp = res.data;
                if(resp.code=='200'){
                    console.log(resp.data);
                    if(resp.data){
                        this.price = resp.data.price;
                    }
                }else{
                    this.$message.error(resp.data);
                } 
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
        handleBuy(){
            if(!this.cart) return;
            if(!this.cpassword){
                this.$message.error("请输入资金密码");
                return;
            }
            if(!this.addressInfo){
                this.$message.error('请填写联系人信息');
                return;
            }
            if(!this.price){
                this.$message.error('未获取到BCT价格');
                return;
            }
            if(this.loading) return;
            this.loading = true;
            this.$http.call(this.$http.api.mallBuyGoods, {
                data: {
                    goods_id: this.cart.goods_id,
                    quantity: this.cart.quantity,
                    password: this.cpassword
                }
            }).then(res=>{
                this.loading = false;
                let resp = res.data;
                if(resp.code=='200'){
                    if(resp.data){
                        console.log(resp.data);
                        this.$router.push({name: 'orders'});
                    }
                }else{
                    this.$message.error(resp.data);
                } 
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            });
        },
    }
}
</script>
<style lang="less" scoped>
.checkout{
    padding: 180px 50px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #f1f1f1;
    .header{
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        background: #fff;
        z-index: 1;
        color: #333;
        font-size: 16PX;
        display:flex;
        align-items: center;
        height: 125px; line-height: 125px;
        .back{
            position: absolute;
            left: 50px;
            color:#333;
        }
        .title{
            flex: 1;
        }
    }
    .goods-list {
        margin: 64px auto;
        .title {
            text-align: left;
            font-weight: bold;
            margin-bottom: 24px;
        }
        .goods-card {
            padding: 36px;
            border-radius: 12px;
            background: #ffffff;
            box-shadow: 0 0 24px rgba(0,0,0,0.1);
            display: flex;
            justify-content: space-between;
            position: relative;
            .goods-image {
                width: 240px; height: 240px;
                border-radius: 24px;
                margin-right: 36px;
            }
            .goods-info {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                text-align: left;
            }
            .goods-cost {
                position: absolute;
                bottom: 24px; right: 24px;
                color: @primary-color;
            }
        }
    }
    .payment-type {
        margin: 64px auto;
        .title {
            text-align: left;
            font-weight: bold;
            margin-bottom: 24px;
        }
        .payment-card {
            padding: 36px;
            border-radius: 12px;
            background: #ffffff;
            box-shadow: 0 0 24px rgba(0,0,0,0.1);
            display: flex;
            justify-content: space-between;
            align-items: center;
            .payment-icon {
                width: 100px; height: 100px;
                border-radius: 24px;
                margin-right: 36px;
            }
            .payment-title {
                flex: 1;
                text-align: left;
                .price {
                    color: @gray;
                    font-size: 32px;
                }
            }
            .payment-check {
                font-size: 50px;
                color: green;
            }
        }
    }
    .security-password {
        margin: 64px auto;
        .title-label {
            font-weight: bold;
            margin-bottom: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .forget-pass {
                font-weight: normal;
                color: #a7a7a7;
            }
        }
        
    }

    .paybar {
        background: #ffffff;
        box-shadow: 0 -12px 24px rgba(128, 12, 12, 0.1);
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-weight: bold;
        .total-cost {
            margin-right: 36px;
            .cost {
                font-size: 55px;
                color: @primary-color;
                .symbol {
                    font-size: 36px;
                    color: #a7a7a7;
                }
            }
        }
    }
}
</style>
