<template>
    <div class="stepper">
      <a-icon @click="decrement" class="stepper-button" type="minus-circle" />
      <input type="number" disabled :value="currentValue" @input="onInput" class="stepper-input" />
      <a-icon @click="increment" class="stepper-button" type="plus-circle" />
    </div>
  </template>
  
  <script>
  export default {
    name: "Stepper",
    props: {
      value: {
        type: Number,
        required: true
      },
      step: {
        type: Number,
        default: 1
      },
      min: {
        type: Number,
        default: -Infinity
      },
      max: {
        type: Number,
        default: Infinity
      }
    },
    data() {
      return {
        currentValue: this.value
      };
    },
    watch: {
      value(newValue) {
        this.currentValue = newValue;
      }
    },
    methods: {
      increment() {
        if (this.currentValue + this.step <= this.max) {
          this.currentValue += this.step;
          this.$emit('input', this.currentValue);
        }
      },
      decrement() {
        if (this.currentValue - this.step >= this.min) {
          this.currentValue -= this.step;
          this.$emit('input', this.currentValue);
        }
      },
      onInput(event) {
        let value = Number(event.target.value);
        if (value >= this.min && value <= this.max) {
          this.currentValue = value;
          this.$emit('input', this.currentValue);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .stepper {
    display: flex;
    align-items: center;
  }
  
  .stepper-button {
    font-size: 64px;
    color: #a7a7a7;
  }
  
  .stepper-input {
    width: 100px;
    border: 0;
    font-size: 36px;
    text-align: center;
    margin: 0 12px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    outline: none;
  }
  </style>