<template>
<div class="address">
    <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">{{ $t('shop.address.title') }}</span>
    </div>

    <div class="box">
        <form @submit.prevent="updateAddress">
            <a-form-item label="Country" :rules="[ { required: true, message: 'Country is required' } ]">
                <a-input v-model="addressInfo.country" placeholder="Enter country" />
            </a-form-item>
            <a-form-item label="Province" :rules="[ { required: true, message: 'Province is required' } ]">
                <a-input v-model="addressInfo.province" placeholder="Enter province" />
            </a-form-item>
            <a-form-item label="City" :rules="[ { required: true, message: 'City is required' } ]">
                <a-input v-model="addressInfo.city" placeholder="Enter city" />
            </a-form-item>
            <a-form-item label="District" :rules="[ { required: true, message: 'District is required' } ]">
                <a-input v-model="addressInfo.area" placeholder="Enter district" />
            </a-form-item>
            <a-form-item label="Detailed Address" :rules="[ { required: true, message: 'Detailed address is required' } ]">
                <a-input v-model="addressInfo.address" placeholder="Enter detailed address" />
            </a-form-item>
            <a-form-item label="Contact Person" :rules="[ { required: true, message: 'Contact person is required' } ]">
                <a-input v-model="addressInfo.username" placeholder="Enter contact person" />
            </a-form-item>
            <a-form-item label="Contact Number" :rules="[ { required: true, message: 'Contact number is required' } ]">
                <a-input v-model="addressInfo.phone" placeholder="Enter contact number" />
            </a-form-item>
            <a-form-item>
                <a-button type="primary" html-type="submit">Submit</a-button>
            </a-form-item>
        </form>
    </div>

</div>
  
</template>
<script>

export default {
  name: "Address",
  data() {
    return {
      loading: false,
      addressInfo: {}
    };
  },
  components:{
  },
  mounted() {
      this.getData();
  },
  methods: {
    getData(){
        if(this.loading) return;
        this.loading = true;
        this.$http.call(this.$http.api.getUserAddress).then(res=>{
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                if(resp.data){
                    this.addressInfo = resp.data;
                }
            }else{
                this.$message.error(resp.data);
            } 
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        });
    },
    updateAddress(){
        if(this.loading) return;
        this.loading = true;
        this.$http.call(this.$http.api.postUserAddress,{
            data: this.addressInfo
        }).then(res=>{
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                this.$message.success('Update success');
            }else{
                this.$message.error(resp.data);
            } 
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        });
    }
  },
};
</script>
<style lang="less" scoped>
.address{
    padding: 150px 50px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #fafafa;
    .header{
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        background: #fff;
        z-index: 1;
        color: #333;
        font-size: 16PX;
        display:flex;
        align-items: center;
        height: 125px; line-height: 125px;
        .back{
            position: absolute;
            left: 50px;
            color:#333;
        }
        .title{
            flex: 1;
        }
    }
    .box {
        border-radius: 12px;
        margin: 1rem auto;
        padding: 28px;
        position: relative;
        text-align: left;
        border: 0;
        box-shadow: none;
    }
}
</style>
